import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_URL } from 'src/app/store/network';
import { setToken } from 'src/entities/core/api/coreSlice';
import { IResponseGetUserApi } from './types';
// import { setToken } from 'src/games/roulette/slices/rouletteSlice';

interface IRequestRegister {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

interface IRequestLogin {
  email: string;
  password: string;
}

const getCookie = (cookieName: string): string | undefined => {
  const cookieArray = document.cookie.split(';');

  for (const cookie of cookieArray) {
    let cookieString = cookie;

    while (cookieString.charAt(0) == ' ') {
      cookieString = cookieString.substring(1, cookieString.length);
    }
    if (cookieString.indexOf(cookieName + '=') == 0) {
      return cookieString.substring(cookieName.length + 1, cookieString.length);
    }

  }

  return undefined;
}

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    // credentials: 'include',
    // prepareHeaders: (headers: Headers, api): Headers => {
    //   const token = (api.getState() as TRootState).roulette.token;
    //   console.log(token)
    //   if (typeof token == 'string') {
    //     headers.set('X-XSRF-TOKEN', token);
    //   }

    //   return headers;
    // }
  }),
  tagTypes: ['USER_MAIN'],
  endpoints: (builder) => ({
    getToken: builder.query<any, string>({
      query: () => ({
        url: 'sanctum/csrf-cookie',
      }),
      async onQueryStarted(
        _,
        { dispatch, queryFulfilled }
      ) {
        await queryFulfilled;
        const cookie = getCookie('XSRF-TOKEN');
        if (typeof cookie != 'undefined') {
          dispatch(setToken(decodeURIComponent(cookie)))
        }
      },
      transformResponse: () => {
        const cookie = getCookie('XSRF-TOKEN');
        if (typeof cookie != 'undefined') return decodeURIComponent(cookie);

        return '';
      }
    }),
    login: builder.mutation<any, IRequestLogin>({
      query: ((body) => ({
        url: 'login',
        method: 'post',
        body,
      })),
      invalidatesTags: ['USER_MAIN'],
      transformResponse: (response: { token: string }) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('isAuth', 'true');
        return response;
      },
    }),
    register: builder.mutation<any, IRequestRegister>({
      query: ((body) => ({
        url: 'register',
        method: 'post',
        body,
      })),
      invalidatesTags: ['USER_MAIN'],
      transformResponse: (response: { token: string }) => {
        localStorage.setItem('token', response.token);
        localStorage.setItem('isAuth', 'true');
        return response;
      },
    }),
    logout: builder.mutation<any, null>({
      query: (() => {
        const token = localStorage.getItem('token');
        return ({
          url: 'logout',
          method: 'post',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      }),
      transformResponse: (response: { token: string }) => {
        localStorage.removeItem('token');
        localStorage.removeItem('isAuth');
        return response;
      },
    }),
    getUser: builder.query<IResponseGetUserApi, null>({
      query: (() => {
        const token = localStorage.getItem('token');
        return ({
          url: 'user',
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        })
      }),
      providesTags: ['USER_MAIN']
    })
  })
});

export const {
  useGetUserQuery,
  useGetTokenQuery,
  useLoginMutation,
  useRegisterMutation,
  useLogoutMutation,
} = userApi;
