import React, { FC } from 'react';
import { FAKE_API_URL } from 'src/app/store/network';
import { useGetFakeServerUserQuery, useUpdateFakeServerUserMutation } from '../../api/fakeServerUserApi';

interface ITableUserBlockProps {
  apiKey?: string;
};

const TableUserBlock: FC<ITableUserBlockProps> = ({
  apiKey,
}) => {
  const {
    data: users
  } = useGetFakeServerUserQuery(null);

  const [updateUser, {
    isLoading: isLoadingUpdate
  }] = useUpdateFakeServerUserMutation();

  const baseUrl = `${FAKE_API_URL}/${apiKey}`;
  const REQUESTS = [
    {
      title: 'Login',
      method: 'POST',
      url: `${FAKE_API_URL}/login`,
      requests: [
        {
          field: 'email',
          type: 'string'
        },
        {
          field: 'password',
          type: 'string'
        },
      ]
    },
    {
      title: 'Register',
      method: 'POST',
      url: `${baseUrl}/register`,
      requests: [
        {
          field: 'email',
          type: 'string'
        },
        {
          field: 'password',
          type: 'string'
        },
        {
          field: 'repeat_password',
          type: 'string'
        },
      ]
    },
    {
      title: 'Posts',
      method: 'GET',
      url: `${FAKE_API_URL}/posts`,
      requests: [
        {
          field: 'Bearer (header)',
          type: 'access_token'
        },
      ]
    },
    {
      title: 'Refresh token',
      method: "POST",
      url: `${FAKE_API_URL}/refresh`,
      requests: [
        {
          field: 'Bearer (header)',
          type: 'access_token'
        },
        {
          field: 'refresh_token',
          type: 'string'
        },
      ]
    },
  ];

  const onClickToken = (fakeUserId: number, body: {
    access_token_status?: boolean;
    refresh_token_status?: boolean;
  }) => {
    updateUser({
      fakeUserId,
      body,
    })
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <div className="text-xl">Endpoints</div>
        <div className="flex flex-col gap-4">
          {REQUESTS.map((req) => (
            <div key={req.url}>
              <div className="flex gap-3 bg-gray-300 text-black w-[80%] px-2 py-1">
                <div className="w-[100px]">{req.title}</div>
                <div className="w-[50px]">{req.method}</div>
                <div>{req.url}</div>
              </div>
              {req.requests && (
                <div className="px-4">
                  <div>Requests</div>
                  {req.requests.map((reqtem) => (
                    <div className="flex gap-3">
                      <div className="w-[150px]">{reqtem.field}:</div>
                      <div>{reqtem.type};</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <div>
        <div>Users</div>
        <div className="flex gap-3 border w-fit p-2">
          <div className="w-[200px]">Email</div>
          <div className="w-[150px]">AccessToken</div>
          <div className="w-[150px]">RefreshToken</div>
        </div>
        <div className="flex flex-col gap-1">
          {users?.data.map((user) => (
            <div className="flex gap-3 px-2">
              <div className="w-[200px]">{user.email}</div>
              <div className="w-[150px] flex gap-2 items-center">
                {!isLoadingUpdate && (
                  <button
                    className="bg-white text-dark p-1"
                    onClick={() => onClickToken(
                      user.id,
                      {
                        access_token_status: !user?.access_token_status
                      }
                    )}
                  >
                    {user?.access_token_status ? 'Off' : 'On'}
                  </button>
                )}
                <div>
                  {user?.access_token_status ? 'Access' : 'Denied'}
                </div>
              </div>
              <div className="w-[150px] flex gap-2 items-center">
                <button
                  className="bg-white text-dark p-1"
                  onClick={() => onClickToken(
                    user.id,
                    {
                      refresh_token_status: !user?.refresh_token_status
                    }
                  )}
                >
                  {user?.refresh_token_status ? 'Off' : 'On'}
                </button>
                <div>
                  {user?.refresh_token_status ? 'Access' : 'Denied'}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
};

export default TableUserBlock;