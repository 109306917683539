import { FC, useEffect } from "react"
import ReactGAImplementation from "react-ga4"
import CoursesList from "src/entities/course/ui/CoursesList"
import PromoCourse from "src/entities/course/ui/PromoCourse"
import LessonsPromoList from "src/entities/lesson/ui/LessonsPromoList"
import ServicesList from "src/entities/service/ui/ServicesList"

interface IMainPageProps {}

const MainPage: FC<IMainPageProps> = ({}) => {
  ReactGAImplementation.initialize([
    {
      trackingId: "G-T4P6KVN738",
    },
  ]);
  useEffect(() => (
    ReactGAImplementation.send({ hitType: "pageview", page: "/", title: "Main Page" })
  ), []);
  return (
    <div className="flex bg-dark min-h-screen">
      <div className="flex-1 text-white p-10 flex flex-col gap-6 max-md:p-5 max-md:pb-[100px] max-[1100px]:gap-3 max-[1100px]:p-6">
        <div className="font-primary text-4xl max-md:text-2xl max-md:text-xl">
          Освітня платформа <span className="font-secondary">PROMOTICH</span>
        </div>
        <LessonsPromoList />
        <ServicesList />
        <CoursesList />
      </div>
      <div className="w-[400px] bg-dark-1 max-md:h-[100px] max-md:w-full max-md:fixed max-md:bottom-0">
        <PromoCourse />
      </div>
    </div>
  )
}

export default MainPage
