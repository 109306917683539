import { FC } from "react"
import { promoLessons } from "../static/promoLessons"
import CategoryIcon from "src/entities/category/ui/CategoryIcon"

interface ILessonsPromoListProps {}

const LessonsPromoList: FC<ILessonsPromoListProps> = ({}) => {
  return (
    <div className="font-primary flex flex-col gap-3">
      <div className="font-secondary">#USECASES</div>
      {promoLessons.map(({ title, icon, link }) => (
        <a className="flex gap-2" target="_blank" href={link}>
          <div className="w-[60px] h-[60px] bg-light rounded flex justify-center items-center">
            <CategoryIcon iconName={icon} />
          </div>
          <div className="h-[60px] bg-light rounded text-dark-3 font-medium flex-1 flex justify-between items-center px-6 text-xl max-[1100px]:text-base max-md:text-sm">
            <div>{title}</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 4.16732L13.3333 10.0007L7.5 15.834"
                stroke="#9BA5B7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </a>
      ))}
    </div>
  )
}

export default LessonsPromoList
