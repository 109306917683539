import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit"
import coreSlice from "src/entities/core/api/coreSlice"
import { userApi } from "src/entities/user/api/userApi"
import adminFakeServerApi from "./store/adminFakeServerApi"

export const store = configureStore({
  reducer: {
    core: coreSlice,
    [userApi.reducerPath]: userApi.reducer,
    [adminFakeServerApi.reducerPath]: adminFakeServerApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      userApi.middleware,
      adminFakeServerApi.middleware,
    ]),
})

export type AppDispatch = typeof store.dispatch
export type TRootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  TRootState,
  unknown,
  Action<string>
>
