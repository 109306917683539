import { FC } from 'react';
import { Link } from 'react-router-dom';
import { ROUTES } from 'src/app/router/routes';


interface IServicesListProps {}

const services = [
  {
    title: "Fake Server API",
    link: "service/fake-server",
    authLink: ROUTES.cabinet.home,
  },
]

const ServicesList: FC<IServicesListProps> = ({}) => {
  const isAuth = localStorage.getItem('isAuth');

  return (
    <div className="font-primary flex flex-col gap-3">
      <div className="font-secondary">#SERVICES</div>
      {services.map(({ title, link, authLink }) => (
        <Link className="flex gap-2" to={isAuth ? authLink : link}>
          <div className="h-[60px] bg-light rounded text-dark-3 font-medium flex-1 flex justify-between items-center px-6 text-xl max-[1100px]:text-base max-md:text-sm max-md:h-[45px]">
            <div>{title}</div>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.5 4.16732L13.3333 10.0007L7.5 15.834"
                stroke="#9BA5B7"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </Link>
      ))}
    </div>
  )
}

export default ServicesList
