import { ReactNode } from "react";

type UICheckboxProps = {
  checked: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  children: ReactNode;
}

export const UICheckbox = ({
  checked,
  onChange,
  children,
}: UICheckboxProps) => (
  <div className="">
    <label
      className="flex gap-1 items-center"
      htmlFor="uiCheckbox"
    >
      <div className="relative">
        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="0.5" y="0.5" width="22" height="22" rx="2.5" fill="#000614" fill-opacity="0.8" stroke="url(#paint0_linear_1_54)" />
          <defs>
            <linearGradient id="paint0_linear_1_54" x1="1" y1="11.5" x2="22" y2="11.5" gradientUnits="userSpaceOnUse">
              <stop stop-color="#582BF2" />
              <stop offset="1" stop-color="#BB0CF8" />
            </linearGradient>
          </defs>
        </svg>
        {checked && (
          <svg
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] rounded-[3px] overflow-hidden"
            width="23" height="23" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="21" height="21" rx="2" fill="url(#paint0_linear_0_1)" />
            <path d="M8.89999 10.5L10.15 11.75L12.65 9.25" stroke="#BDBDBD" stroke-linecap="round" stroke-linejoin="round" />
            <rect x="6" y="6" width="9" height="9" rx="1.5" stroke="#BDBDBD" />
            <defs>
              <linearGradient id="paint0_linear_0_1" x1="-3.91155e-08" y1="10.5" x2="21" y2="10.5" gradientUnits="userSpaceOnUse">
                <stop stop-color="#582BF2" />
                <stop offset="1" stop-color="#BB0CF8" />
              </linearGradient>
            </defs>
          </svg>
        )}
      </div>
      <div>
        {children}
      </div>
    </label>
    <input
      id="uiCheckbox"
      type="checkbox"
      checked={checked}
      onChange={onChange}
      className="hidden"
    />
  </div>
);
