import { FAST_CONFIG } from "src/app/fastConfig";

export const COURSES = [
  {
    title: 'Middle Frontend',
    link: FAST_CONFIG.mainPromoCourse.link,
  },
  {
    title: 'OpenAI',
    link: 'https://www.udemy.com/course/ai-promotich-course/?couponCode=PROMOTICH'
  },
  {
    title: 'Blender',
    link: 'https://www.udemy.com/course/3d-blender-2023/?couponCode=PROMOTICH1'
  },
  {
    title: 'Unity',
    link: 'https://www.udemy.com/course/promotich-3d-unity-2023/?couponCode=PROMOTICH1'
  },
]
