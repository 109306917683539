import { FC } from 'react';
import { CATEGORY_ICONS } from '../static/categoryIcons';
import reduxImg from 'assets/categories/redux.png';

interface ICategoryIconProps {
  iconName: `${CATEGORY_ICONS}`;
};

const CategoryIcon:FC<ICategoryIconProps> = ({
  iconName
}) => {
  switch (iconName){
    case CATEGORY_ICONS.REDUX_TOOLKIT:
      return (
        <img className="w-[80%]" src={reduxImg} />
      );
    default:
      return (
        <></>
      )
  }
};

export default CategoryIcon;