import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/app/router/routes';
import { Button } from 'src/shared/ui/Button';

interface IPreviewBlockProps {

};

const PreviewBlock: FC<IPreviewBlockProps> = ({ }) => {
  const navigate = useNavigate();
  const onClick = () => {
    navigate(ROUTES.auth.login);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="text-lg">
        <div>Інструмент - який дозволяє використовувати fake запити для розробки додатків.</div>
        <div>По факту - це аналог <span className="italic">jsonplaceholder</span>, але з більш поглибленим функціоналом.</div>
      </div>
      <Button
        onClick={onClick}
      >
        Створити власний Fake API
      </Button>
    </div>
  )
};

export default PreviewBlock;
