import adminFakeServerApi from "src/app/store/adminFakeServerApi";
import { IResponseGetFakeServerUserApi } from "./types";
import { IFakeServerUser } from "../model/FakeServerUser";

const enhancedApi = adminFakeServerApi.enhanceEndpoints({
  addTagTypes: ['USER'],
})

export const fakeServerUserApi = enhancedApi.injectEndpoints({
  endpoints: (builder) => ({
    getFakeServerUser: builder.query<IResponseGetFakeServerUserApi, any>({
      query: () => '/users',
      providesTags: ['USER']
    }),
    updateFakeServerUser: builder.mutation<any, {
      fakeUserId: number;
      body: Pick<IFakeServerUser, 'access_token_status' | 'refresh_token_status'>;
    }>({
      query: ({ fakeUserId, body }) => ({
        url: `/users/${fakeUserId}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ['USER']
    })
  })
});

export const {
  useGetFakeServerUserQuery,
  useUpdateFakeServerUserMutation,
} = fakeServerUserApi