import React, { FC, useState, useEffect } from "react";
import { UICheckbox } from "src/shared/ui/core/UICheckbox";
import { UIButton } from "src/shared/ui/core/UIButton";
import { UIInput } from "src/shared/ui/core/UIInput";
import { useRegisterMutation } from "../../api/userApi";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "src/app/router/routes";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";

interface IRegistrationFormProps { }

interface IFormData {
  name: string;
  email: string;
  password: string;
  repeatPassword: string;
}

function isFetchBaseQueryError(
  error: any,
  field: string
): boolean {
  if (error) {
    if (error?.data?.error[field]) return true;
  }
  return false
}

const RegistrationForm: FC<IRegistrationFormProps> = ({ }) => {
  const [checked, setChecked] = React.useState(false);
  const handleChange = () => {
    setChecked(!checked);
  };
  const [form, setForm] = useState<IFormData>({
    name: "",
    email: "",
    password: "",
    repeatPassword: "",
  });

  const [registerUser, {
    isSuccess: isSuccessRegistration,
    isError,
    error,
  }] = useRegisterMutation();

  if (isSuccessRegistration) {
    return <Navigate to={ROUTES.cabinet.home} />
  }

  const handleClick = () => {
    registerUser({
      ...form,
    });
  };

  console.log(error)

  return (
    <div>
      <UIInput
        value={form.name}
        placeholder="Name"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            name: e.target.value,
          }))
        }
        icon="name"
        error={isError && isFetchBaseQueryError(error, 'name')}
      />
      <UIInput
        value={form.password}
        placeholder="Password"
        type="password"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            password: e.target.value,
          }))
        }
        icon="password"
        error={isError && isFetchBaseQueryError(error, 'password')}
      />
      <UIInput
        value={form.repeatPassword}
        placeholder="Repeat password"
        type="password"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            repeatPassword: e.target.value,
          }))
        }
        icon="password"
        error={isError && isFetchBaseQueryError(error, 'repeatPassword')}
      />
      <UIInput
        value={form.email}
        placeholder="E-mail"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            email: e.target.value,
          }))
        }
        icon="email"
        error={isError && isFetchBaseQueryError(error, 'email')}
      />
      <div className="w-full flex items-center text-[11px] text-darkgray font-medium leading-normal justify-between mb-3">
        <UICheckbox checked={checked} onChange={handleChange}>
          I’ve read the rules
        </UICheckbox>
      </div>
      <UIButton onClick={handleClick}>sign up</UIButton>
    </div>
  );
};

export default RegistrationForm;
