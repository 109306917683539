import { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { UICheckbox } from "src/shared/ui/core/UICheckbox";
import { UIButton } from "src/shared/ui/core/UIButton";
import { UIInput } from "src/shared/ui/core/UIInput";
import { useLoginMutation } from "../../api/userApi";
import { ROUTES } from "src/app/router/routes";

interface IFormData {
  name: string;
  email: string;
  password: string;
}

const LoginForm = () => {
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [form, setForm] = useState<IFormData>({
    name: "",
    email: "",
    password: "",
  });

  const navigate = useNavigate();
  const [loginUser, { isSuccess, isError }] = useLoginMutation();

  const onChangeRememberMe = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRememberMe(e.target.checked);
  };

  const handleClick = () => {
    loginUser({
      email: form.email,
      password: form.password,
    });
  };

  if (isSuccess) {
    return <Navigate to={ROUTES.cabinet.home} />
  }

  return (
    <div>
      <UIInput
        value={form.email}
        placeholder="Email"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            email: e.target.value,
          }))
        }
        icon="name"
        error={isError}
      />
      <UIInput
        value={form.password}
        placeholder="Password"
        type="password"
        onChange={(e) =>
          setForm((prev) => ({
            ...prev,
            password: e.target.value,
          }))
        }
        icon="password"
        error={isError}
      />

      <div className="w-full flex items-center text-[11px] text-darkgray font-medium leading-normal justify-between">
        <div className="flex items-center mb-3">
          <UICheckbox checked={rememberMe} onChange={onChangeRememberMe}>
            Remember me
          </UICheckbox>
        </div>
        <div>
          <p className="underline cursor-pointer mb-4">Forgot password?</p>
        </div>
      </div>
      <UIButton onClick={handleClick}>log in</UIButton>
    </div>
  );
};

export default LoginForm;
