import { ReactNode } from 'react';

type ButtonProps = {
  onClick: () => void;
  children: ReactNode;
}

export const Button = ({
  onClick,
  children,
}: ButtonProps) => (
  <button onClick={onClick} className="border px-3 py-2 rounded w-fit">
    {children}
  </button>
);