import { InputHTMLAttributes, useState, useRef } from "react";
import "./UIInput.css";
import { twMerge } from 'tailwind-merge';

interface UIInputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon: string;
  className?: string;
  error?: boolean;
}

interface LibraryIcons {
  [key: string]: JSX.Element;
}

const LIBRARY_ICONS: LibraryIcons = {
  name: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="2.5" stroke="#474756" />
      <path
        d="M17.6667 11.6667C17.6667 13.1394 16.4728 14.3333 15 14.3333C13.5272 14.3333 12.3333 13.1394 12.3333 11.6667C12.3333 10.1939 13.5272 9 15 9C16.4728 9 17.6667 10.1939 17.6667 11.6667Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 16.3333C12.4227 16.3333 10.3333 18.4227 10.3333 21H19.6667C19.6667 18.4227 17.5773 16.3333 15 16.3333Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  password: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="2.5" stroke="#474756" />
      <path
        d="M15 17V18.3333M11 21H19C19.7364 21 20.3333 20.403 20.3333 19.6667V15.6667C20.3333 14.9303 19.7364 14.3333 19 14.3333H11C10.2636 14.3333 9.66666 14.9303 9.66666 15.6667V19.6667C9.66666 20.403 10.2636 21 11 21ZM17.6667 14.3333V11.6667C17.6667 10.1939 16.4728 9 15 9C13.5272 9 12.3333 10.1939 12.3333 11.6667V14.3333H17.6667Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  ),
  closeEye: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 8C9.99996 9.10457 9.10453 10 7.99996 10C6.89539 10 5.99996 9.10457 5.99996 8C5.99996 6.89543 6.89539 6 7.99996 6C9.10453 6 9.99996 6.89543 9.99996 8Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.63879 7.99998C2.48831 5.29525 5.01517 3.33333 8.00025 3.33333C10.9854 3.33333 13.5122 5.29528 14.3617 8.00003C13.5122 10.7048 10.9854 12.6667 8.00027 12.6667C5.01516 12.6667 2.4883 10.7047 1.63879 7.99998Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
  eye: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.99996 8C9.99996 9.10457 9.10453 10 7.99996 10C6.89539 10 5.99996 9.10457 5.99996 8C5.99996 6.89543 6.89539 6 7.99996 6C9.10453 6 9.99996 6.89543 9.99996 8Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.63879 7.99998C2.48831 5.29525 5.01517 3.33333 8.00025 3.33333C10.9854 3.33333 13.5122 5.29528 14.3617 8.00002C13.5122 10.7047 10.9854 12.6667 8.00027 12.6667C5.01516 12.6667 2.4883 10.7047 1.63879 7.99998Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 4L3 12"
        stroke="#474756"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  ),
  email: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="29" height="29" rx="2.5" stroke="#474756" />
      <path
        d="M9 12.3333L14.2604 15.8403C14.7083 16.1388 15.2917 16.1388 15.7396 15.8403L21 12.3333M10.3333 19.6667H19.6667C20.403 19.6667 21 19.0697 21 18.3333V11.6667C21 10.9303 20.403 10.3333 19.6667 10.3333H10.3333C9.59695 10.3333 9 10.9303 9 11.6667V18.3333C9 19.0697 9.59695 19.6667 10.3333 19.6667Z"
        stroke="#474756"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

export const UIInput = ({ icon, type, error, ...props }: UIInputProps) => {
  const { value, placeholder, onChange } = props;

  const isPasswordInput = type === "password";
  const [isPasswordVisible, setPasswordVisibility] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const togglePasswordVisibility = () => {
    setPasswordVisibility((prev) => !prev);
  };

  return (
    <div className="relative mb-3">
      <div className={twMerge(
        "flex items-center w-full border-[1px] border-box border-boxdark rounded",
        error && "border-red-400"
      )}>
        <div className="absolute left-[16px] top-1/2 transform -translate-y-1/2">
          {LIBRARY_ICONS[icon]}
        </div>
        <input
          ref={inputRef}
          type={
            isPasswordInput ? (isPasswordVisible ? "text" : "password") : "text"
          }
          className={twMerge(
            "block rounded pl-[57px] pb-3.5 pt-6 pr-9 w-full text-[15px] tracking-[0.9px] font-medium text-white appearance-none peer focus:outline-none bg-transparent",
          )}
          placeholder=" "
          value={value}
          onChange={onChange}
        />
        {isPasswordInput && (
          <div
            className={twMerge(
              "absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer",
            )}
            onClick={togglePasswordVisibility}
          >
            {isPasswordVisible ? LIBRARY_ICONS.closeEye : LIBRARY_ICONS.eye}
          </div>
        )}
        <label
          htmlFor="input"
          className={twMerge(
            "absolute text-[13px] font-medium text-darkgray duration-300 top-50% left-[57px] peer-focus:text-blue-600 peer-focus:top-25% peer-focus:text-[11px] peer-focus:-translate-y-3",
            value && "-translate-y-3",
            error && 'text-red-400'
          )}
          onClick={() => inputRef.current?.focus()}
        >
          {placeholder}
        </label>
      </div>
    </div>
  );
};
