import { FC, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "src/app/hooks";
import { ROUTES } from "src/app/router/routes";
import { selectIsAuth } from "src/entities/core/api/coreSlice";
import RegistrationForm from "src/entities/user/ui/form/RegistrationForm";

interface IRegisterPageProps {}

const RegisterPage: FC<IRegisterPageProps> = ({}) => {
  const isAuth = localStorage.getItem('isAuth');
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth){
      navigate(ROUTES.cabinet.home);
    };
  }, [isAuth]);
  return (
    <div className="flex flex-col justify-center items-center h-screen text-center bg-dark text-white font-secondary">
      <div>
        <h1 className="font-extrabold text-[31px] leading-[38px] content-center custom-gradient-text">
          REGISTRATION
        </h1>
        <p className="leading-normal text-gray text-[14px] content-center mb-[21px]">
          Welcome to ProMotich
        </p>
      </div>
      <RegistrationForm />
      <p className="text-[11px] text-darkgray font-medium leading-normal mt-3">
        I have the account.{" "}
        <Link
          to={ROUTES.auth.login}
          className="cursor-pointer custom-gradient-text-under"
        >
          Log In
        </Link>
      </p>
    </div>
  );
};

export default RegisterPage;
