import { createBrowserRouter } from "react-router-dom";
import { ROUTES } from "./routes";
import MainPage from "src/pages/MainPage";
import LoginPage from "src/pages/auth/LoginPage";
import RegisterPage from "src/pages/auth/RegisterPage";
import FakeServerPage from "src/pages/service/FakeServerPage";
import CabinetPage from "src/pages/CabinetPage";
import { protectedFunc } from "./helpers/protectedFunc";

export const router = createBrowserRouter([
  {
    path: ROUTES.main,
    // Component: MainLayout,
    // loader: protectedFunc,
    children: [
      {
        index: true,
        element: <MainPage />,
      }
    ]
  },
  {
    path: 'auth',
    // loader: protectedFunc,
    // Component: AuthLayout,
    children: [
      {
        path: ROUTES.auth.login,
        element: <LoginPage />
      },
      {
        path: ROUTES.auth.register,
        element: <RegisterPage />
      },
    ]
  },
  {
    path: 'cabinet',
    loader: protectedFunc,
    children: [
      {
        index: true,
        element: <CabinetPage />,
      },
    ]
  },
  {
    path: 'service',
    // loader: protectedFunc,
    children: [
      {
        path: 'fake-server',
        element: <FakeServerPage />,
      },
    ]
  },
]);