import { ReactNode } from "react";

type UIButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: ReactNode;
  className?: string;
};

export function UIButton({ children, className, ...props }: UIButtonProps) {
  return (
    <div>
      <button
        {...props}
        className="w-full h-[60px] text-white font-extrabold text-[19px] uppercase border rounded"
      >
        {children}
      </button>
    </div>
  );
}
