import { CATEGORY_ICONS } from "src/entities/category/static/categoryIcons";

export const promoLessons = [
  {
    icon: CATEGORY_ICONS.REDUX_TOOLKIT,
    title: 'RTK-Query. Як зробити сучасну обробку даних з API та редюсери зручними?',
    link: 'https://youtu.be/mhJwkOGLBd8'
  },
  {
    icon: CATEGORY_ICONS.REDUX_TOOLKIT,
    title: 'JWT Авторизація від А до Я. React + TS 2024 Нарешті все стане зрозуміло',
    link: 'https://youtu.be/vV2Vi5Rj7ro'
  },
  {
    icon: CATEGORY_ICONS.REDUX_TOOLKIT,
    title: 'OpenAI. ReplicateAI NextJS 14. React. Redux-toolkit. Rtk - створення AI додатків',
    link: 'https://youtu.be/7G7tnIRZ87w'
  },
];
