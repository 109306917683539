import { FC } from 'react';
import PromoCourse from 'src/entities/course/ui/PromoCourse';
import InfoBlock from 'src/entities/service/fakeServer/ui/infoBlock/InfoBlock';
import PreviewBlock from 'src/entities/service/fakeServer/ui/previewBlock/PreviewBlock';
import { Button } from 'src/shared/ui/Button';

interface IFakeServerPageProps {

};

const FakeServerPage: FC<IFakeServerPageProps> = ({ }) => {

  return (
    <div className="flex bg-dark min-h-screen">
      <div className="flex-1 text-white p-10 flex flex-col gap-6 max-md:p-5 max-md:pb-[100px] max-[1100px]:gap-3 max-[1100px]:p-6">
        <div className="flex-1 text-white p-10 flex flex-col gap-6">
          <div className="font-primary text-4xl">
            Fake Server API
          </div>
          <PreviewBlock />
          {/* <InfoBlock /> */}
        </div>
      </div>
      <div className="w-[400px] bg-dark-1 max-md:h-[100px] max-md:w-full max-md:fixed max-md:bottom-0">
        <PromoCourse />
      </div>
    </div>
  )
};

export default FakeServerPage;