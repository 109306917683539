import { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/app/router/routes';
import { useGetUserQuery, useLogoutMutation } from 'src/entities/user/api/userApi';
import { Button } from 'src/shared/ui/Button';
import { useEffect } from 'react';
import TableUserBlock from 'src/entities/service/fakeServer/ui/tableUserBlock/TableUserBlock';
import PromoCourse from 'src/entities/course/ui/PromoCourse';

interface ICabinetPageProps {

};

const CabinetPage: FC<ICabinetPageProps> = ({ }) => {
  const {
    data: user,
    isSuccess: isSuccessUser
  } = useGetUserQuery(null);

  const [logout, {
    isSuccess: isSuccessLogout
  }] = useLogoutMutation();

  const navigate = useNavigate();

  const onExit = () => {
    logout(null);
  };

  useEffect(() => {
    if (isSuccessLogout) {
      navigate(ROUTES.auth.login);
    }
  }, [isSuccessLogout])



  return (
    <div className="flex bg-dark min-h-screen">
      <div className="flex-1 text-white px-10 py-4 flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="flex gap-6 items-center">
            <div className="font-primary text-2xl">
              Кабінет <span className="font-secondary">PROMOTICH</span>
            </div>
            <Link to={ROUTES.main}>На головну</Link>
          </div>
          <Button onClick={onExit}>
            Вийти
          </Button>
        </div>

        {isSuccessUser && (
          <div>
            <div>Hi, {user?.name}!</div>
            <div>Your public API Key: {user?.public_api_key}</div>
          </div>
        )}
        <TableUserBlock
          apiKey={user?.public_api_key}
        />
      </div>
      <div className="w-[400px] bg-dark-1 max-md:h-[100px] max-md:w-full max-md:fixed max-md:bottom-0">
        <PromoCourse />
      </div>
    </div>
  )
};

export default CabinetPage;