import { createSlice } from "@reduxjs/toolkit";
import { TRootState } from 'src/app/store';

interface InitialState {
  token?: string;
  isAuth: boolean;
}

const initialState: InitialState = {
  isAuth: false,
}

const coreSlice = createSlice({
  initialState,
  name: 'coreSlice',
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload
    },
  }
});

export const {
  setToken
} = coreSlice.actions;

export const selectIsAuth = (state: TRootState) => state.core.isAuth;

export default coreSlice.reducer;