import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'src/app/router/routes';
import LoginForm from 'src/entities/user/ui/form/LoginForm';

interface ILoginPageProps {

};

const LoginPage:FC<ILoginPageProps> = ({}) => {
  const navigate = useNavigate();
  const isAuth = localStorage.getItem('isAuth');

  const onClick = () => {
    navigate(ROUTES.auth.register);
  };


  return (
    <div className="font-secondary flex flex-col justify-center items-center h-screen text-center bg-dark text-white bg-cover">
      <div>
        <h1 className="font-extrabold text-[31px] leading-[37.98px] content-center custom-gradient-text">
          AUTHORIZATION
        </h1>
        <p className="font-normal not-italic leading-normal text-gray text-[14px] content-center mb-[21px]">
          Welcome to PROMOTICH
        </p>
      </div>
      <LoginForm />
      <p className="text-[11px] text-darkgray font-medium leading-normal mt-3">
        Don’t have an account yet?{" "}
        <span onClick={onClick} className="cursor-pointer custom-gradient-text-under">
          Register now
        </span>
      </p>
    </div>
  )
};

export default LoginPage;