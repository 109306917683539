import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { FAKE_API_URL } from "./network";

const adminFakeServerApi = createApi({
  reducerPath: 'adminFakeServerApi',
  baseQuery: fetchBaseQuery({
    baseUrl: FAKE_API_URL,
    prepareHeaders: (headers) => {
      const token = localStorage.getItem('token');

      if (token){
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Accept', 'application/json');
      }
      return headers;
    }
  }),
  endpoints: () => ({}),
});

export default adminFakeServerApi;
