import { ReactNode } from "react"
import frontendImg from "assets/course1.jpg"
import blenderImg from "assets/course2.png"
import unityImg from "assets/course3.jpg"
import openAIImg from "assets/course4.png"
import { COURSES } from "../static/courses"

const CourseListItem = ({
  img,
  link,
  children,
}: {
  img: string
  link: string
  children: ReactNode
}) => (
  <a href={link} target="_blank" className="flex flex-col gap-2 w-[30%]">
    <img src={img} />
    {children}
  </a>
)

const CoursesList = () => {
  const [frontend, openai, blender, unity] = COURSES
  return (
    <div className="font-primary flex flex-col gap-3">
      <div className="font-secondary">#COURSES</div>
      <div className="flex flex-wrap gap-6 max-md:flex-col">
        <CoursesList.Item img={openAIImg} link={openai.link}>
          <div className="flex flex-col gap-1">
            <div className="font-secondary text-lg max-md:text-sm max-[1100px]:text-base">
              AI FULLSTACK COURSE
            </div>
            <div className="text-sm text-light-2 max-md:text-xs">
              Як використовувати можливості AI на 100% та бути в тренді IT
              OpenAI. ReplicateAI NextJS 14. React - створення <span className="text-warning uppercase">
                5 AI FullStack додатків
              </span>
            </div>
          </div>
        </CoursesList.Item>
        <CoursesList.Item img={frontendImg} link={frontend.link}>
          <div className="flex flex-col gap-1">
            <div className="font-secondary text-lg max-md:text-sm max-[1100px]:text-base">
              MIDDLE+ FRONTEND
            </div>
            <div className="text-sm text-light-2 max-md:text-xs">
              Створіть <span className="text-warning uppercase">GameMarketplace</span> з
              3 іграми. Отримайте знання MIDDLE+ рівня та підкорить ринок праці
              у Європі та Україні
            </div>
          </div>
        </CoursesList.Item>
        <CoursesList.Item img={blenderImg} link={blender.link}>
          <div className="flex flex-col gap-1">
            <div className="font-secondary text-lg max-md:text-sm max-[1100px]:text-base">
              START BLENDER COURSE
            </div>
            <div className="text-sm text-light-2 max-md:text-xs">
              Пориньте у світ створення дизайну для ігор. Створіть 32 моделі для
              шахів <span className="text-warning">
                з нуля до яскравого результату
              </span>
            </div>
          </div>
        </CoursesList.Item>
        <CoursesList.Item img={unityImg} link={unity.link}>
          <div className="flex flex-col gap-1">
            <div className="font-secondary text-lg max-md:text-sm max-[1100px]:text-base">
              START UNITY COURSE
            </div>
            <div className="text-sm text-light-2 max-md:text-xs">
              Створюйте 3D ігри та захоплюйте світовий ринок разом з PROMOTICH.
              Опануйте трендову професію - <span className="text-warning uppercase">
                Junior Unity Dev (C#)
              </span>
            </div>
          </div>
        </CoursesList.Item>

      </div>
    </div>
  )
}

export default CoursesList

CoursesList.Item = CourseListItem
