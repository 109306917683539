import { FC } from "react"
import courseImg from "assets/course1.jpg"
import { FAST_CONFIG } from "src/app/fastConfig"

interface IPromoCourseProps {}

const promoCourse = {
  tags: ["Middle Frontend 2024", "React", "Typescript"],
}

const PromoCourse: FC<IPromoCourseProps> = ({}) => {
  return (
    <div className="fixed font-primary text-white p-6 h-screen flex flex-col justify-between max-md:w-full max-md:px-10">
      <div className="flex flex-col gap-4 max-md:hidden">
        <div>
          <div className="text-2xl font-medium max-[1100x]:text-xl">
            Новий курс
          </div>
          <div className="flex flex-wrap gap-1 text-info text-sm">
            {promoCourse.tags.map((tag) => (
              <div className="text-inherit">#{tag}</div>
            ))}
          </div>
        </div>
        <img src={courseImg} />
        <div>
          <div className="font-secondary text-2xl max-[1100x]:text-xl">
            MIDDLE+ FRONTEND
          </div>
          <div className="text-sm">
            створюємо власний <span className="text-warning uppercase">GameMarketplace</span>
          </div>
        </div>
        <div className="text-sm flex flex-col gap-1 text-light-2">
          Час підняти свій рівень Front-End до нових висот!
          <p>
            Цей проект присвячений тому, щоб ви стали спеціалістом, який буде
            відрізнятися від тисячі інших програмістів базового рівня, які зараз
            знаходяться у пошуку роботи.
          </p>
          <ul>
            <div className="font-medium">Texнології</div>
            <li>- Core framework - React + Vite</li>
            <li>- State manager - Redux Toolkit</li>
            <li>- Api manager - RTK Query</li>
            <li>- Styles - Tailwind CSS</li>
            <li>- Canvas - ReactPIXI</li>
          </ul>
        </div>
      </div>
      <a
        target="_blank"
        href={FAST_CONFIG.mainPromoCourse.link}
        style={{
          borderRadius: 6,
          border: "1px solid var(--888, #9227FF)",
          background:
            "linear-gradient(90deg, rgba(146, 39, 255, 0.85) 0%, rgba(69, 65, 217, 0.85) 26%, rgba(0, 193, 255, 0.85) 51%, rgba(34, 118, 221, 0.85) 76%, rgba(178, 37, 244, 0.85) 100%)",
        }}
        className="text-center block text-lg font-primary py-3"
      >
        Детальніше
      </a>
    </div>
  )
}

export default PromoCourse
